/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/montserrat-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/montserrat-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
/*@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/oswald-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}*/
/* latin */
/*@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/oswald-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}*/


/* latin-ext */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/merriweather-latin-ext.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Merriweather';
  font-style: normal;
  font-weight: 300;
  src: url(/assets/fonts/merriweather-latin.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

html, body {
	font: 14px/1.21;
	font-weight: 400;
}

h1 {
	text-align: center;
}

h1 a {
	color: inherit;
	text-decoration: none;
}

h1 a:hover {
	text-decoration: underline;
}

pre {
	white-space: break-spaces;
	font-family: inherit;
}

a {
	text-decoration: underline;
	cursor: pointer;
}
  

body {
  background-color: #f2f4f8;
  color: #111;
  font-family: "Montserrat", Arial, sans-serif;
  margin: 0.62rem;
}

a {
  color: #c60001; /* red */
  text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Merriweather", Georgia, serif;
  color: #333;
  margin-bottom: 0.5em;
}

p {
  line-height: 1.5;
  margin-bottom: 1em;
  max-width: 50em;
}

ul, ol {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

li {
  margin-bottom: 0.5em;
}

code {
  font-family: "Courier New", monospace;
  color: #333; /* dark gray */
  background-color: #f2f4f8; /* light blue */
  padding: 2px 5px;
  border-radius: 5px;
}

textarea {
	font-size: 1.16em;
	padding: 1rem;
	min-height: 10em;
	max-width: 80em;
	width: calc(100vw - 3.26rem - 2px);
  border: 1px solid #708a70;
}

header h1 {
	text-align: left;
}

nav a,
nav .previous,
nav .next {
	display: inline-block;
	padding: 0.38em 0.62em 0.38em 0;
}

nav a.none {
	color: #777;
	cursor: default;
}

nav a.none:hover {
	text-decoration: none;
}

form label {
	height: 1.6em;
}

.error {
	color: red;
	font-weight: bold;
}

.Card article {
	display: flex;
	flex-direction: column;
	position: relative;
}

.Card article > main {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

blockquote {
	display: inline-block;
  font-style: italic;
  line-height: 1.38;
  font-size: 1.38em;
  border: 0.5px solid #fac0c0;
  padding: 2.6rem 2.6rem 2.6rem 4.2rem;
  box-sizing: border-box;
  align-self: flex-start;
	margin: 1rem 1rem 1rem 0;
	width: calc(84% - 1rem);
  max-width: 46rem;
	min-width: 500px;
}

.images {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.images figure {
	margin-top: 0;
}

.images img {
	display: inline-block;
	padding: 2.6rem;
	border: 0.5px solid #d6d0d0;
	margin: 1rem 0;
  box-sizing: border-box;
  height: calc(100vh - 3.6rem * 2);
  min-width: 500px;
  min-height: 500px;
}

.images figcaption {
	margin-top: calc(-1rem);
  text-align: right;
  margin-right: 2.6rem;
  font-style: italic;
  max-width: calc(100vh - 3.6rem * 2);
}

.images figcaption:before,
.images figcaption:after {
	content: '"';
}

.stagger {
	position: relative;
	display: inline-block;
	width: 100%;
}

.stagger-text {
	visibility: hidden;
	width: 100%;
	max-width: 50em;
}

.stagger-current {
	position: absolute;
	top: 0;
	left: 0;
	max-width: 50em;
/*	width: 104%;*/
}

button,
a.button {
	font-size: 1.16rem;
	padding: 0.38rem 0.62rem;
	border-width: 1px;
	border-radius: 0.38rem;
	cursor: pointer;
	color: #305030;
	border-left-color: transparent;
	border-top-color: transparent;
	background-color: #a0e0a0;
	border-right-color: #c0fac0;
	border-bottom-color: #c0fac0;
	font-family: "Montserrat", Arial, sans-serif;
	font-weight: bold;
}

button:hover,
a.button:hover {
	border-left-color: #70ea70;
	border-top-color: #70ea70;
	border-right-color: transparent;
	border-bottom-color: transparent;
	background-color: #a0e9a0;
	text-decoration: none;
	color: #209a20;
}

.card-complete {
	margin: 0.62rem 0 1.62rem;
}

.review {
	align-self: flex-end;
	white-space: nowrap;
}

.review .controls {
	padding: 2.6rem;
/*	padding-right: 4.6rem;*/
	display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.review .controls fieldset {
	margin: 0;
}

.review .controls button {
	font-size: 2rem;
	padding: 1.24rem;
	margin: 0 1rem 0 0;
  width: 2.4em;
  height: 2.4em;
	border-radius: 50%;
	border: 1px solid;
	border-top-color: transparent;
	border-left-color: transparent;
	background-color: #b0a0a0;
	border-block-start-color: transparent;
	border-block-end-color: transparent;
}

.review .controls button:hover {
/*	transform: translate(1px, 2px);*/
/*	border-color: transparent;*/
	border-top-color: gray;
	border-right-color: transparent;
	border-bottom-color: transparent;
	border-left-color: gray;
}

.review .controls button.yes {
	border-right-color: #c0fac0;
	border-bottom-color: #c0fac0;
	background-color: #a0b0a0;
}

.review .controls fieldset:not([data-rating]) button.yes:hover,
.review .controls fieldset[data-rating="true"] button.yes {
/*	border-color: #70fa70;*/
	border-left-color: #70da70;
	border-top-color: #70da70;
	border-right-color: transparent;
	border-bottom-color: transparent;
	background-color: #a0e0a0;
}

.review .controls button.no {
	border-right-color: #fac0c0;
	border-bottom-color: #fac0c0;
	background-color: #b0a0a0;
}

.review .controls fieldset:not([data-rating]) button.no:hover,
.review .controls fieldset[data-rating="false"] button.no {
/*	border-color: #fa7070;*/
	border-left-color: #ea7070;
	border-top-color: #ea7070;
	border-right-color: transparent;
	border-bottom-color: transparent;
	background-color: #e0a0a0;
}

.review .controls button[disabled] {
	border-color: #eee;
	color: #777;
	cursor: wait;
}

.review .controls button:last-child {
	margin-right: 0;
}

.review .controls fieldset {
  border: 0.5px solid #fac0c0;
  padding: 1.6em 2.6em;
}

.review .controls fieldset[data-rating] button[disabled] {
	cursor: default;
}

.review .controls fieldset[data-rating="true"] button.no,
.review .controls fieldset[data-rating="false"] button.yes {
	visibility: hidden;
}

@media (max-width: 1400px) {
	.Card article > main {
		flex-direction: column;
	}

	blockquote {
  	align-self: center;
		width: calc(100vh - 3.6rem * 2);
		max-width: none;
	  margin: 1rem 0;
	}

	.review {
		padding-right: calc(50vw - 512px + 2.6rem);
	}
}

@media (max-width: 800px) {
	blockquote {
  	width: 100vw;
  	font-size: 1.24rem;
		min-width: auto;
		border-left-width: 0;
		border-right-width: 0;
		padding-top: 1.62rem;
		padding-bottom: 1.62rem;
	}

	.images img {
		width: 100vw;
		height: 100vw;
		min-width: auto;
		min-height: auto;
		border-left-width: 0;
		border-right-width: 0;
	}

	.review .controls {
		padding-right: 2.6rem;
	}
}

@media (max-width: 500px) {
	blockquote {
  	font-size: 1.16rem;
  	padding-left: 1.62rem;
  	padding-right: 1.62rem;
	}
}